* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 14px;
}

p {
  margin: 10px 0;
}

h2 {
  font-size: 2.5rem;
  margin: 0 auto;
}

.hello {
  font-size: 2rem;
  font-weight: bold;
}
.intro {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.8em;
}
.projects {
  text-decoration: underline;

  color: white;
  margin: 0 0.3em;
}
.projects:hover {
  transition: 0.8s;
}

.work {
  background: url(assets/office.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.company {
  font-size: 1.5em;
  font-weight: 300;
  margin: 0.5em 0;
  color: white;
}
.engineer {
  font-size: 1.3em;
  font-weight: 300;
  font-style: italic;
}
.capital {
  font-size: 1.2em;
  line-height: 1.5em;
  letter-spacing: 0.02em;
}

.footyTitle {
  color: white;
}

.message {
  display: flex;
  background: #343641;
  padding: 0.7em 1em;
  text-decoration: none;
  justify-content: space-evenly;
  width: 180px;
  align-items: center;
  border-radius: 3px;
  height: 40px;
}
.message:hover {
  background-color: #34394b;
  transition: 0.5s;
}
.messageMe {
  font-size: 1.2em;
}

.github {
  color: #808080;
  border-radius: 100px;
}
.github:hover {
  background-color: #34394b;
  transition: 0.5s;
}
.links {
  margin: 4em 1em 1em 1em;
}

/* MEDIA QUERIES  */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  h2 {
    font-size: 3rem;
  }
  .hello {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .intro {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.8em;
  }
  .message {
    height: 40px;
  }
}
